import React, { useEffect, useState } from "react"
import { ArticleItem } from "../components/ArticleItem"
import { Page } from "../components/Page"

type ListDocument = {
  slug: string
  wallet: string
  createTime: number
  updateTime: number
  amountUpvotes: number
  title: string
  writer?: { twitterHandle: string; picture: string; label: string }
}

const listDocuments = async () => {
  const resultRes = await fetch(
    `${process.env.GATSBY_BACKEND_ENDPOINT}listDocuments/desc/upvotes`
  )
  return resultRes.json()
}

export default function Home({ params }: { params: { query: string } }) {
  const [documents, setDocuments] = useState([] as ListDocument[])

  useEffect(() => {
    listDocuments().then(setDocuments)
  }, [params.query])

  return (
    <Page>
      <h3>Popular documents</h3>
      <div className="threeToOneColumnCount">
        {documents.map(document => (
          <div key={document.slug}>
            <ArticleItem
              to={`/d/${document.slug}`}
              label={document.writer?.label}
              writer={document.writer?.twitterHandle || document.wallet}
              title={document.title}
              amountUpvotes={document.amountUpvotes}
              picture={document.writer?.picture}
            />
          </div>
        ))}
      </div>
    </Page>
  )
}
